(function () {
  'use strict';
  /* global _*/
  const UM_MINUTO_EM_SEGUNDOS = 60;
  class OcorrenciasCtrl {
    constructor($q, $scope, $uibModal, HttpServiceV3g, AlertMessage, AlertasEnum, FeatureFlag,
      Keys, ServiceVfiltro, FactoryVfiltro, ModalInfoConfirmService, moment, $translate, Authenticator, ConvertersService,
      ConfigEventosService) {
      this.q = $q;
      this.scope = $scope;
      this.modalService = $uibModal;
      this.httpService = HttpServiceV3g;
      this.alertMessage = AlertMessage;
      this.alertasEnum = AlertasEnum;
      this.featureFlag = FeatureFlag;
      this.keys = Keys;
      this.serviceVfiltro = ServiceVfiltro;
      this.selectOptions = [];
      this.configEventosService = ConfigEventosService;
      this.modalInfoConfirmService = ModalInfoConfirmService;
      this.isOwnConfigs = false;
      this.hasRoleConfigOcorrenciaEditar = false;
      this.moment = moment;
      this.translate = $translate;
      this.authenticator = Authenticator;
      this.model = {};
      this.hasPermission = null;
      this.convertersService = ConvertersService;
      this.disableButton = false;
      this.tooltipErrorMessage = '';
      this.classes = {
        todas: [
          'GRAVISSIMA',
          'GRAVE',
          'MEDIA',
          'LEVE'
        ],
        GRAVISSIMA: {label: this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.eventosConfiguraveis.adicioneEventos.eventoInfo.classe.gravissima')},
        GRAVE: {label: this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.eventosConfiguraveis.adicioneEventos.eventoInfo.classe.grave')},
        MEDIA: {label: this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.eventosConfiguraveis.adicioneEventos.eventoInfo.classe.moderada')},
        LEVE: {label: this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.eventosConfiguraveis.adicioneEventos.eventoInfo.classe.leve')}
      };

      this.configsOcorrencia = [];
      this.configsBkp = [];
      this.configsSalvar = null;

      this.authenticator.getUser().then(user => {
        this.measurementUnits = user.measurementUnits;
        const isKm = this.measurementUnits.distanceMeasurement === 'KM',
            translationKeyPrefix = 'ce.configuracao.eventos.itensConfiguraveis.ocorrencias.eventosConfiguraveis.adicioneEventos.eventoInfo.',
            measurementKey = isKm ? 'velocidadeMinimaKMH' : 'velocidadeMinimaMPH',

            translateMeasurement = (suffix) => this.translate.instant(`${translationKeyPrefix}${measurementKey}.${suffix}`);

        this.velocidadeMinimaTitulo = translateMeasurement('titulo');
        this.velocidadeMinimaOpcional = translateMeasurement('opcional');
        this.velocidadeMinimaExtraInfo = translateMeasurement('extraInfo');
        this.velocidadeMaximaPermitida = translateMeasurement('velocidadeMaximaPermitida');
      });

      this.configEventosService.getConfigEventos().then(configEventos => {
        this.selectOptions = this.getAlertasFiltrados(configEventos);
      });

      this.serviceVfiltro.init(FactoryVfiltro.get([
        {
          key: this.keys.uo.name
        }
      ]))
        .then((filtro) => {
          this.uoId = filtro.find(x => x.key === this.keys.uo.name).value.id;
          this.hasPermission = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('CONFIG_OCORRENCIA_VISUALIZAR');
          this.hasRoleConfigOcorrenciaEditar = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('CONFIG_OCORRENCIA_EDITAR');
          if (this.featureFlag.SHOW_EVENT_DIALOG) {
            this.showOpenDialogEventPriority();
          }
          this.consultar();
        });
    }

    getAlertasFiltrados(configEventos) {
      return this.alertasEnum.getTiposFiltered(configEventos).filter(alerta => {
        return alerta.id !== 'EXCESSO_VELOCIDADE' &&
          alerta.id !== 'EXCESSO_VELOCIDADE_20' &&
          alerta.id !== 'EXCESSO_VELOCIDADE_30' &&
          alerta.id !== 'EXCESSO_VELOCIDADE_ACIMA_30' &&
          alerta.id !== 'EXCESSO_VELOCIDADE_POR_VIA' &&
          alerta.id !== 'DIAGNOSTICO_OBD' &&
          alerta.id !== 'VEICULO_SEM_SINAL' &&
          alerta.id !== 'RFID_NAO_CADASTRADO' &&
          alerta.id !== 'SEM_IDENTIFICACAO_DE_MOTORISTA' &&
          alerta.id !== 'GENERIC' &&
          alerta.id !== 'BOTAO_PANE' &&
          alerta.id !== 'SEM_EPI' &&
          alerta.id !== 'STABILITY_CONTROL';
      });
    }

    consultar() {
      this.httpService.get(`/configuracao/configuracoes/${this.uoId}/ocorrencia`)
        .then(consult => {
          consult.data
            .sort(this.handleSortEvents)
            .forEach(ocorr => {
              if (ocorr.intervaloTempo) {
                ocorr.intervaloTempo /= UM_MINUTO_EM_SEGUNDOS;
              } else {
                ocorr.intervaloTempo = null;
              }
              if (ocorr.velocidadeMinima && ocorr.velocidadeMinima !== 0) {
                ocorr.velocidadeMinima = this.measurementUnits.distanceMeasurement === 'KM' ? ocorr.velocidadeMinima : Number(this.convertersService.handleKmToMiles(ocorr.velocidadeMinima));
              }
            });
          this.configsOcorrencia = consult.data;
          this.configsOcorrencia.forEach(config => config.backend = true);
          this.configsBkp = _.cloneDeep(this.configsOcorrencia);
          this.isOwnConfigs = !this.hasConfigsUoPai(consult.data);
          this.filterSelectOptions(this.configsOcorrencia.map(ocorr => ocorr.tipo));
          this.handleTooltip();
          if (!this.isOwnConfigs) {
            this.emiteAlertaConfigUoPai();
          }
        })
        .catch(() => {
          this.isOwnConfigs = true;
          this.alertMessage.create({
            type: 'error',
            message: this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.eventosConfiguraveis.erroAoCarregarConfiguracoes'),
            appendTo: '.alerta-top'
          });
        });
    }

    hasConfigsUoPai(configs) {
      const hasConfigsUoPai = configs.length > 0 && configs[0].uoId !== this.uoId;
      return hasConfigsUoPai;
    }

    emiteAlertaConfigUoPai() {
      this.alertMessage.create({
        type: 'warning',
        message: `${this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.eventosConfiguraveis.erroUOPermissao.uoNaoPossuiPermissao')}
        <strong> ${this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.eventosConfiguraveis.erroUOPermissao.configDeOcorrencias')}</strong>.
        `,
        appendTo: '.alerta-permissao',
        timeout: 15000
      });
    }

    filterSelectOptions(eventosId) {
      eventosId.forEach(eventoId => {
        this.selectOptions = this.selectOptions
          .filter(evento => evento.id !== eventoId);
      });
    }

    adicionarConfig() {
      this.configsOcorrencia.push({
        tipo: this.model.eventoSelected.id,
        intervaloTempo: null,
        uoId: this.uoId,
        classe: null,
        velocidadeMinima: null,
        quantidade: 1,
        backend: false
      });

      this.filterSelectOptions([this.model.eventoSelected.id]);
      this.model.eventoSelected = null;
      this.handleChangeButton(true);
    }

    removerEvento(index, $event) {
      $event.stopPropagation();
      $event.preventDefault();

      const eventoKey = this.configsOcorrencia[index].tipo,
          descricao = this.translate.instant(this.alertasEnum.getAlerta(eventoKey).descricao);

      this.modalInfoConfirmService.confirmModal({
        title: this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.eventosConfiguraveis.adicioneEventos.removerConfiguracaoModal.titulo'),
        btnSecundario: this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.eventosConfiguraveis.adicioneEventos.removerConfiguracaoModal.voltar'),
        btnPrimario: this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.eventosConfiguraveis.adicioneEventos.removerConfiguracaoModal.remover'),
        conteudo: `
                  ${this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.eventosConfiguraveis.adicioneEventos.removerConfiguracaoModal.prefixMsgRemoverConfiguracao')}
                  ${descricao}
                  ${this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.eventosConfiguraveis.adicioneEventos.removerConfiguracaoModal.sufixMsgRemoverConfiguracao')}
                  `,
        headerColor: 'bg-red',
        typeButton: 'btn-danger'
      }).then(() => {
        this.removerConfig(index, eventoKey);
      });
    }

    removerConfig(index) {
      if (this.configsOcorrencia[index].backend) {
        this.removerConfigBackend(index);
      } else {
        this.removerConfigFrontend(index);
      }
    }

    removerConfigBackend(index) {
      const tipo = this.configsOcorrencia[index].tipo,
          classe = this.configsOcorrencia[index].classe;
      this.httpService.delete(`/configuracao/configuracoes/${this.uoId}/ocorrencia/${tipo}/classe/${classe}`)
        .then(() => {
          this.removerConfigFrontend(index);
          const indexBkp = this.configsBkp.findIndex(config => config.tipo === tipo);
          this.configsBkp.splice(indexBkp, 1);
        })
        .catch(() => {
          this.alertMessage.create({
            type: 'danger',
            message: this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.eventosConfiguraveis.adicioneEventos.removerConfiguracaoModal.msgErro'),
            appendTo: '.alerta-aqui',
            timeout: 5000
          });
        });
    }

    removerConfigFrontend(index) {
      this.adicionaEventosSelect(this.configsOcorrencia[index].tipo);
      this.configsOcorrencia.splice(index, 1);
      if (!this.disableButton) {
        this.handleTooltip();
      }
    }

    descartarAlteracoes() {
      this.modalInfoConfirmService.confirmModal({
        title: this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.modalDescartarAlteracoes.titulo'),
        conteudo: this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.modalDescartarAlteracoes.descricao'),
        btnSecundario: this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.modalDescartarAlteracoes.cancelar'),
        btnPrimario: 'OK',
        headerColor: 'bg-red',
        typeButton: 'btn-danger'
      }).then(() => {
        this.configsOcorrencia.forEach(config => {
          if (!config.backend) {
            this.adicionaEventosSelect(config.tipo);
          }
        });
        this.configsOcorrencia = _.cloneDeep(this.configsBkp);
        this.handleChangeButton(false);
      });
    }

    adicionaEventosSelect(eventoKey) {
      this.selectOptions.push(this.alertasEnum.getAlerta(eventoKey));
    }

    geraConfigsParaBackend() {
      this.configsSalvar = _.cloneDeep(this.configsOcorrencia);
      this.configsSalvar.forEach(config => {
        if (config.intervaloTempo) {
          config.intervaloTempo *= UM_MINUTO_EM_SEGUNDOS;
        }
        if (config.velocidadeMinima && config.velocidadeMinima !== 0) {
          config.velocidadeMinima = this.measurementUnits.distanceMeasurement === 'KM' ? config.velocidadeMinima : Number(this.convertersService.handleMilesToKm(config.velocidadeMinima));
        }
        return config;
      });
    }

    salvar() {
      if (this.scope.ocorrenciasForm.$valid) {
        if (this.configsOcorrencia.length >= 1) {
          this.geraConfigsParaBackend();
          this.modalService.open({
            animation: true,
            backdrop: 'static',
            templateUrl: 'configuracoes/eventos/subpaginas/ocorrencias/modal-simulador/modal-simulador.tpl.html',
            controller: 'ModalSimuladorCtrl',
            controllerAs: '$ctrl',
            resolve: {
              configsOcorrencia: () => this.configsSalvar
            }
          }).result
            .then(res => {
              this.modalConfirmacaoSalvar(res);
            })
            .catch(error => {
              if (error === 'ERRO_SIMULADOR') {
                this.emiteAlertaErroSalvar();
              }
            });
        } else {
          this.salvarConfigsBackend();
        }
      } else {
        this.configsOcorrencia.forEach(ocorr => {
          this.scope.ocorrenciasForm[ocorr.tipo].$setDirty();
          this.scope.ocorrenciasForm[ocorr.tipo][`classificacao_${ocorr.tipo}`].$setDirty();
        });
      }
    }

    emiteAlertaErroSalvar() {
      this.alertMessage.create({
        type: 'error',
        message: this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.salvar.erro'),
        appendTo: '.alerta-aqui'
      });
    }

    modalConfirmacaoSalvar(res) {
      const data = this.moment(res.data).format('DD/MM/YYYY');
      this.modalInfoConfirmService.confirmModal({
        title: this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.salvar.modal.titulo'),
        conteudo: `<div>
                    ${this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.salvar.modal.divConfirmarSalvar.noDiaSelecionadoMsg')}
                    <em>(${data})</em>,
                    ${this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.salvar.modal.divConfirmarSalvar.seriaGeradosAproximadamenteMsg')}
                    <strong> ${res.ocorrencias} ${this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.salvar.modal.divConfirmarSalvar.ocorrenciasMsg')}</strong>
                    ${this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.salvar.modal.divConfirmarSalvar.noTotal')}
                  </div>
                  <div>
                    ${this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.salvar.modal.divConfirmarSalvar.desejaProsseguirMsg')}
                  </div>`,
        btnSecundario: this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.salvar.modal.voltar'),
        btnPrimario: this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.salvar.modal.salvar')
      })
        .then(() => {
          this.salvarConfigsBackend();
        });
    }

    salvarConfigsBackend() {
      this.httpService.post(`/configuracao/configuracoes/${this.uoId}/ocorrencia`, this.configsSalvar)
        .then(() => {
          this.alertMessage.create({
            type: 'success',
            message: this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.salvar.sucessoMsg'),
            appendTo: '.alerta-aqui',
            timeout: 5000
          });
          this.configsOcorrencia.forEach(config => config.backend = true);
          this.handleChangeButton(false);
        })
        .catch(() => {
          this.emiteAlertaErroSalvar();
        });
    }

    showOpenDialogEventPriority() {
      /* eslint-disable */
      const abrirModal = document.querySelector('#openDialogEventPriority'),
        modal = document.createElement('vfwc-open-dialog-event-priority'); /* eslint-enable */
      modal.uoId = this.uoId;
      modal.enableOptions = false;
      abrirModal.appendChild(modal);
      this.onEventPriority(modal);
      this.scope.$watch(() => this.scope.ocorrenciasForm.$dirty, (isDirty) => this.handleChangeButton(isDirty));
    }

    onEventPriority(element) {
      element.addEventListener('eventPriorityEmitter', () => {
        this.consultar();
      });
    }

    handleChangeButton(disableButton, tooltipErrorMessage) {
      /* eslint-disable */
      const element = document.getElementById('event-priority-button')/* eslint-enable */
      element.disabled = disableButton;
      this.disableButton = disableButton;
      if (disableButton) {
        element.classList.add('mat-button-disabled');
      } else {
        element.classList.remove('mat-button-disabled');
      }
      this.tooltipErrorMessage = tooltipErrorMessage || 'ce.tempoReal.quadroDeOcorrencias.dialogEventPriority.radio.tooltipDisabled';
    }

    handleTooltip() {
      if (!this.configsOcorrencia.length) {
        this.handleChangeButton(true, 'ce.tempoReal.quadroDeOcorrencias.dialogEventPriority.radio.tooltipDisabledEmpty');
      } else if (this.configsOcorrencia.some((config) => config.uoId !== this.uoId)) {
        this.handleChangeButton(true, 'ce.tempoReal.quadroDeOcorrencias.dialogEventPriority.radio.tooltipDisabledUO');
      } else {
        this.handleChangeButton(false);
      }
    }

    handleSortEvents(a, b) {
      if (a.presentationPriority === b.presentationPriority) {
        return 0;
      } else if (!a.presentationPriority) {
        return 1;
      } else if (!b.presentationPriority) {
        return -1;
      }
      return a.presentationPriority - b.presentationPriority;
    }

    msgError(error, campo) {
      return error.required && `${this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.eventosConfiguraveis.adicioneEventos.eventoInfo.erro.campoObrigatorio')}` ||
        error.min && `${this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.eventosConfiguraveis.adicioneEventos.eventoInfo.erro.prefixMsgValorDe')} ${campo} ${this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.eventosConfiguraveis.adicioneEventos.eventoInfo.erro.sufixMsgNaoPodeSerNegativo')}` ||
        error.qtdInteger && `${this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.eventosConfiguraveis.adicioneEventos.eventoInfo.erro.prefixMsgValorDe')} ${campo} ${this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.eventosConfiguraveis.adicioneEventos.eventoInfo.erro.sufixMsgDeveSerInteiro')}`;
    }

    isQtdValid(quantidade) {
      return () => {
        return quantidade > 0;
      };
    }

    isInteger(inputScope) {
      return () => {
        return Number.isInteger(Number(inputScope.$viewValue));
      };
    }

    hasErrorQtd(inputScope) {
      return inputScope.$invalid && (inputScope.$dirty || inputScope.$error.required);
    }

    hasErrorClassificacao(inputScope) {
      const returno = inputScope.$invalid && inputScope.$dirty;
      return returno;
    }

    abrirModalInfo() {
      this.modalService.open({
        animation: true,
        backdrop: 'static',
        templateUrl: 'configuracoes/eventos/subpaginas/ocorrencias/modal-ocorrencias-info/modal-ocorrencias-info.tpl.html',
        controller: 'ModalOcorrenciasInfoCtrl',
        controllerAs: '$ctrl'
      });
    }

    isReadOnly() {
      return !this.isOwnConfigs || !this.hasRoleConfigOcorrenciaEditar;
    }

    isReadWrite() {
      return this.isOwnConfigs && this.hasRoleConfigOcorrenciaEditar;
    }
  }

  angular
    .module('SubpaginasEventos')
    .controller('OcorrenciasCtrl', OcorrenciasCtrl);
}());
