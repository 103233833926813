(function () {
  'use strict';
  /* global Intl*/
  class BdmCtrl {

    constructor($q, $httpParamSerializer, $window, Onboarding, MockBDM, IDBDM, Uos, Motoristas,
                Bdm, v3gTableLimitService, Keys, ServiceVfiltro, FactoryVfiltro, VtabelaFactory, uiGridConstants,
                ObjetosVfiltroService, Restangular, $filter, sorters, CadastroVeiculoService, UrlConfigService, $translate,
                $rootScope, MeasurementUnitConverterService, ConfigEventosService) {
      this.q = $q;
      this.httpParamSerializer = $httpParamSerializer;
      this.veiculoService = CadastroVeiculoService;
      this.configEventosService = ConfigEventosService;
      this.sorters = sorters;
      this.bdmService = Bdm;
      this.v3gTableLimitService = v3gTableLimitService;
      this.onboarding = Onboarding;
      this.mockBDM = MockBDM;
      this.window = $window;
      this.uoService = Uos;
      this.motoristaService = Motoristas;
      this.keys = Keys;
      this.filter = $filter;
      this.restangular = Restangular;
      this.serviceVfiltro = ServiceVfiltro;
      this.objetosVfiltroService = ObjetosVfiltroService;
      this.vtabelaFactory = VtabelaFactory;
      this.uiGridConstants = uiGridConstants;
      this.urlConfigService = UrlConfigService;
      this.translate = $translate;
      this.filtro = null;

      this.vtabela = null;
      this.relatorio = null;
      this.uoSelecionada = null;
      this.currentPage = 0;
      this.tableState = this.objetosVfiltroService.getTableStateUrl();
      this.hasSensorAcionado = false;
      this.hasConsumoEletrico = false;

      this.isDemo = this.onboarding.startOnboarding(IDBDM);
      this.uoService.isDemo = this.isDemo;
      this.motoristaService.isDemo = this.isDemo;
      this.currentTimezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

      this.colunaPedalAcionadoFaixa = this.translate.instant('ce.boletim.common.pedalAcionadoFaixa');

      this.tooltipPedalAcionadoFaixa1 = this.translate.instant('ce.boletim.common.tooltipPedalAcionadoFaixa1');
      this.tooltipPedalAcionadoFaixa2 = this.translate.instant('ce.boletim.common.tooltipPedalAcionadoFaixa2');
      this.tooltipPedalAcionadoFaixa3 = this.translate.instant('ce.boletim.common.tooltipPedalAcionadoFaixa3');

      this.measurementUnitConverterService = MeasurementUnitConverterService;
      this.rootScope = $rootScope;

      this.dateTimeFormat = this.measurementUnitConverterService.getDateFormat(this.rootScope.usuario.measurementUnits.dateTimeFormat);
      this.distanceUnit = this.measurementUnitConverterService.getDistanceUnit(this.rootScope.usuario.measurementUnits.distanceMeasurement);
      this.fuelUnit = this.measurementUnitConverterService.getFuelUnit(this.rootScope.usuario.measurementUnits.fuelMeasurement);
      this.fuelEfficiency = this.measurementUnitConverterService.getFuelEfficiencyUnit(this.rootScope.usuario.measurementUnits.fuelMeasurement);
      this.energyEfficiency = this.measurementUnitConverterService.getEnergyEfficiencyUnit(this.rootScope.usuario.measurementUnits.distanceMeasurement);
      this.decimalFilter = this.rootScope.usuario.idioma === 'PT_BR' ? 'decimalWithLabel:\'' : 'decimalPointWithLabel:\'';

      ServiceVfiltro.init(FactoryVfiltro.get([
        {
          key: this.keys.dateTime.name,
          dateLimit: 30,
          format: 'YYYY-MM-DD',
          defaultValues: {
            ontem: {
              active: true
            },
            ultimos7dias: {
              active: true
            },
            ultimos30dias: {
              active: true
            }
          }
        },
        {
          name: 'uo',
          key: this.keys.uo.name
        },
        {
          name: 'uoParceira',
          key: this.keys.uoParceira.name,
          size: 6
        },
        {
          name: 'motoristas',
          key: this.keys.motoristas.name,
          ignoreValidation: true,
          size: 6
        }
      ]))
      .then((filtro) => {
        this.filtro = filtro;
        this.consultar(ServiceVfiltro.getFiltroBackendNovo(filtro));
      });
    }

    initVtabela(configEventos) {
      let drilDownSorting, drilDownCol, objVtabela = {
            exporterPdfFilename: 'Boletim Diário do Motorista PDF.pdf',
            exporterCsvFilename: 'Boletim Diário do Motorista CSV.csv',
            exporterExcelFilename: 'Boletim Diário do Motorista XLSX.xlsx',
            appScopeProvider: this,
            enableRowSelection: false,
            enableRowHeaderSelection: false,
            multiSelect: false,
            enableSelectAll: false,
            showColumnFooter: true,
            isRowSelectable: () => {
              return false;
            },
            columnDefs: [
                {
                  name: 'motorista',
                  displayName: 'ce.boletim.diarioMotorista.colunas.motorista',
                  pinnedLeft: true,
                  type: 'string',
                  filterCellFiltered: true,
                  enableHiding: false,
                  aggregationTotalTitle: 'Total'
                },
                {
                  name: 'departamento',
                  displayName: 'ce.boletim.diarioMotorista.colunas.departamento',
                  type: 'string',
                  filterCellFiltered: true,
                  enableHiding: false
                },
                {
                  name: 'veiculos',
                  displayName: 'ce.boletim.diarioMotorista.colunas.veiculos',
                  enableHiding: false,
                  filterCellFiltered: true,
                  cellFilter: 'withLabelToUndefined:\'Sem identificação\''
                },
                {
                  name: 'dia',
                  displayName: 'ce.boletim.diarioMotorista.colunas.dia',
                  type: 'date',
                  cellFilter: 'date:\'' + this.dateTimeFormat + '\'',
                  filterCellFiltered: true,
                  enableHiding: false,
                  cellClass: 'text-center'
                },
                {
                  name: 'inicio',
                  displayName: 'ce.boletim.diarioMotorista.colunas.dataInicio',
                  headerTooltip: `ce.boletim.diarioMotorista.colunas.dataInicio`,
                  cellFilter: 'date:\'' + this.dateTimeFormat + '\ HH:mm:ss\'',
                  filterCellFiltered: true,
                  cellClass: 'text-center'
                },
                {
                  name: 'fim',
                  displayName: 'ce.boletim.diarioMotorista.colunas.dataFim',
                  headerTooltip: `ce.boletim.diarioMotorista.colunas.dataFim`,
                  cellFilter: 'date:\'' + this.dateTimeFormat + '\ HH:mm:ss\'',
                  filterCellFiltered: true,
                  cellClass: 'text-center'
                },
                {
                  name: 'distanciaPercorrida',
                  displayName: 'ce.boletim.diarioMotorista.colunas.distanciaPercorrida',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellFilter: 'decimalWithLabelWithoutConversion:\'' + this.distanceUnit + '\'',
                  cellClass: 'text-center',
                  filterCellFiltered: true
                },
                {
                  name: 'tempoIgnicaoLigada',
                  displayName: 'ce.boletim.diarioMotorista.colunas.tempoIgnicaoLigada',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  cellFilter: 'humanizedDuration',
                  cellClass: 'text-center',
                  enableFiltering: true,
                  filterCellFiltered: true
                },
                {
                  name: 'velocidade',
                  displayName: 'ce.boletim.diarioMotorista.colunas.velocidadeExcessiva',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellFilter: 'humanizedDuration',
                  cellClass: 'text-center',
                  filterCellFiltered: true
                },
                {
                  name: 'aceleracoesBruscas',
                  displayName: 'ce.boletim.diarioMotorista.colunas.aceleracoesBruscas',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true
                },
                {
                  name: 'frenagensBruscas',
                  displayName: 'ce.boletim.diarioMotorista.colunas.frenagensBruscas',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  enableFiltering: true,
                  filterCellFiltered: true
                },
                {
                  name: 'curvasBruscas',
                  displayName: 'ce.boletim.diarioMotorista.colunas.curvasBruscas',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true
                },
                {
                  name: 'followingDistance',
                  displayName: 'ce.boletim.diarioMotorista.colunas.followingDistance',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  isDisabled: !(configEventos && configEventos.followingDistance.enabled)
                },
                {
                  name: 'ropBrake',
                  displayName: 'ce.boletim.diarioMotorista.colunas.ropBrake',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  isDisabled: !(configEventos && configEventos.ropBrake.enabled)
                },
                {
                  name: 'ropEngine',
                  displayName: 'ce.boletim.diarioMotorista.colunas.ropEngine',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  isDisabled: !(configEventos && configEventos.ropEngine.enabled)
                },
                {
                  name: 'ycBrake',
                  displayName: 'ce.boletim.diarioMotorista.colunas.ycBrake',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  isDisabled: !(configEventos && configEventos.ycBrake.enabled)
                },
                {
                  name: 'ycEngine',
                  displayName: 'ce.boletim.diarioMotorista.colunas.ycEngine',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  isDisabled: !(configEventos && configEventos.ycEngine.enabled)
                },
                {
                  name: 'banguelas',
                  displayName: 'ce.boletim.diarioMotorista.colunas.banguelas',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellFilter: 'humanizedDuration',
                  cellClass: 'text-center',
                  filterCellFiltered: true
                },
                {
                  name: 'rpmExcessivo',
                  displayName: 'ce.boletim.diarioMotorista.colunas.rpmExcessivo',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  cellFilter: 'humanizedDuration',
                  cellClass: 'text-center',
                  filterCellFiltered: true
                },
                {
                  name: 'pedalAcionadoFaixa1',
                  displayName: this.colunaPedalAcionadoFaixa + ' 1',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  headerCellClass: 'icon-info-tooltip',
                  headerTooltip: this.tooltipPedalAcionadoFaixa1
                },
                {
                  name: 'pedalAcionadoFaixa2',
                  displayName: this.colunaPedalAcionadoFaixa + ' 2',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  headerCellClass: 'icon-info-tooltip',
                  headerTooltip: this.tooltipPedalAcionadoFaixa2
                },
                {
                  name: 'pedalAcionadoFaixa3',
                  displayName: this.colunaPedalAcionadoFaixa + ' 3',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  headerCellClass: 'icon-info-tooltip',
                  headerTooltip: this.tooltipPedalAcionadoFaixa3
                },
                {
                  name: 'paradoLigado',
                  displayName: 'ce.boletim.diarioMotorista.colunas.paradoLigado',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  cellFilter: 'humanizedDuration',
                  cellClass: 'text-center',
                  filterCellFiltered: true
                },
                {
                  name: 'consumoCombustivel',
                  displayName: 'ce.boletim.diarioMotorista.colunas.consumoCombustivel',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  cellFilter: 'decimalWithLabelWithoutConversion:\'' + this.fuelUnit + '\'',
                  cellClass: 'text-center',
                  filterCellFiltered: true
                },
                {
                  name: 'mediaConsumo',
                  displayName: 'ce.boletim.diarioMotorista.colunas.mediaConsumo',
                  cellClass: 'text-center',
                  cellFilter: 'decimalFormatterWithLabel:\'' + this.fuelEfficiency + '\'',
                  filterCellFiltered: true
                },
                {
                  name: 'consumoEletrico',
                  displayName: 'ce.boletim.diarioMotorista.colunas.consumoEletrico',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  cellFilter: this.decimalFilter + 'kWh\'',
                  cellClass: 'text-center',
                  visible: this.hasConsumoEletrico,
                  filterCellFiltered: true
                },
                {
                  name: 'mediaConsumoEletrico',
                  displayName: 'ce.boletim.diarioMotorista.colunas.mediaConsumoEletrico',
                  cellClass: 'text-center',
                  cellFilter: 'decimalFormatterWithLabel:\'' + this.energyEfficiency + '\'',
                  visible: this.hasConsumoEletrico,
                  filterCellFiltered: true
                },
                {
                  name: 'logradouroInicial',
                  displayName: 'ce.boletim.diarioMotorista.colunas.logradouroInicial',
                  filterCellFiltered: true
                },
                {
                  name: 'logradouroFinal',
                  displayName: 'ce.boletim.diarioMotorista.colunas.logradouroFinal',
                  filterCellFiltered: true
                },
                {
                  name: 'detalhe',
                  displayName: '',
                  enableFiltering: false,
                  enableSorting: false,
                  pinnedRight: true,
                  enableColumnResizing: false,
                  enableHiding: false,
                  exporterSuppressExport: true,
                  width: 70,
                  cellTemplate: `
                    <div class="ui-grid-cell-contents vtabela-icone-detalhado">
                      <a
                        id='btn-detalhes-{{$index}}'
                        ng-href='{{grid.appScope.goToBDMDetalhes(row.entity.id, row.entity.dia)}}'>
                        <span class="glyphicon glyphicon-zoom-in" aria-hidden='true'></span>
                      </a>
                    </div>`
                }
            ].filter(x => x.isDisabled !== true)
          };

      if (this.hasSensorAcionado) {
        objVtabela.columnDefs.push({
          name: 'tempoSensorDesengate',
          displayName: 'ce.boletim.diarioMotorista.colunas.sensorAcionado',
          sortWithUndefinedValues: true,
          aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
          cellClass: 'text-center',
          cellTemplate: `
            <div class="ui-grid-cell-contents text-center">
              <span title="{{ row.entity.tempoSensorDesengate | humanizedOrUndefinedWithLabel:\'ce.boletim.diarioMotorista.sensorAcionado.naoSeAplica' | translate }}">
                {{row.entity.tempoSensorDesengate | humanizedOrUndefinedWithLabel:\'N/A\'}}
              </span>
            </div>`,
          cellFilter: 'humanizedOrUndefinedWithLabel:\'N/A\'',
          filterCellFiltered: true
        });
      }

      drilDownSorting = this.getDrilDownSortingDirection(this.tableState);
      drilDownCol = objVtabela.columnDefs.find(x => this.isEventoVtabela(x.name, this.tableState.sort.predicate));

      if (drilDownSorting && drilDownCol) {
        angular.merge(
          drilDownCol,
          drilDownSorting
        );
      }

      return objVtabela;
    }

    isEventoVtabela(eventName, urlEvent) {
      const isSpeedEvent = eventName === 'velocidade',
          isUrlExVelocidadeFaixa = urlEvent === 'exVelocidadeFaixa1' || urlEvent === 'exVelocidadeFaixa2' || urlEvent === 'exVelocidadeFaixa3';

      return eventName.toLowerCase() === urlEvent.toLowerCase() || isUrlExVelocidadeFaixa && isSpeedEvent;
    }

    getItemVtabela(item) {
      return {
        id: item.motorista.id,
        motorista: item.motorista.nome,
        departamento: item.departamento,
        veiculos: item.veiculos.replace('-', ' - '),
        dia: item.dia,
        inicio: item.inicio,
        fim: item.fim,
        distanciaPercorrida: item.distanciaPercorrida,
        tempoIgnicaoLigada: item.tempoDirecao,
        velocidade: item.excessosVelocidade,
        aceleracoesBruscas: item.aceleracoes,
        frenagensBruscas: item.frenagens,
        curvasBruscas: item.curvas,
        banguelas: item.banguelas,
        rpmExcessivo: item.rpmExcessivos,
        paradoLigado: item.tempoMotorOcioso,
        consumoCombustivel: item.consumoCombustivel,
        consumoEletrico: item.consumoEletrico,
        tempoSensorDesengate: item.tempoSensorDesengate,
        logradouroInicial: item.logradouroInicio,
        logradouroFinal: item.logradouroFim,
        mediaConsumo: item.mediaConsumo,
        mediaConsumoEletrico: item.mediaConsumoEletrico,
        pedalAcionadoFaixa1: item.pedalFreioDescidaFaixa1Qtd,
        pedalAcionadoFaixa2: item.pedalFreioDescidaFaixa2Qtd,
        pedalAcionadoFaixa3: item.pedalFreioDescidaFaixa3Qtd,
        followingDistance: item.followingDistance,
        ropBrake: item.ropBrake,
        ropEngine: item.ropEngine,
        ycBrake: item.ycBrake,
        ycEngine: item.ycEngine
      };
    }

    consultar(filtro) {
      if (this.isDemo) {
        this.setInfoTable(
          this.mockBDM.filter(x => x.id === this.serviceVfiltro.filtroCompleto
              .find(item => item.key === this.keys.motoristas.name)
                  .values[0].id)[0].data);
      } else {
        this.bdmService.one().get(angular.extend(filtro, {timezone: this.currentTimezone}))
          .then(data => {
            this.loadGridBdm(data);
          });
      }
    }

    setInfoTable(data) {
      this.vtabela.setData(data.list.map(i => this.getItemVtabela(i)));
    }

    getDrilDownSortingDirection(tableState) {
      if (tableState && tableState.sort.reverse) {
        return {
          sort: {
            direction: this.uiGridConstants.DESC
          }
        };
      }

      return {
        sort: {
          direction: this.uiGridConstants.ASC
        }
      };
    }

    getUrlCsv() {
      let filtro = this.serviceVfiltro.getFiltroBackendNovo(this.filtro);
      return `/boletim/bdm/csv?${this.httpParamSerializer(angular.extend(filtro, {timezone: this.currentTimezone}))}`;
    }

    goToBDMDetalheDemo(id, dia, veiculo) {
      this.window.location.href = `/relatorios/bdm/detalhes/${id}/${dia}?demo&veiculo=${veiculo}`;
    }

    goToBDMDetalhes(id, dia) {
      return `/relatorios/bdm/detalhes/${id}/${dia}`;
    }

    loadGridBdm(data) {
      this.q.all([
        this.veiculoService.hasSensor(data.reqParams.uo),
        this.configEventosService.getConfigEventos()
      ])
      .then(([hasSensor, configEventos]) => {
        this.hasSensorAcionado = hasSensor;
        this.hasConsumoEletrico = data.list.some((registro) => angular.isDefined(registro.mediaConsumoEletrico));
        this.vtabela = this.vtabelaFactory.get(this.initVtabela(configEventos), this.serviceVfiltro.factoryVfiltro.user, 'relatorioBDM', 'v1');
        this.setInfoTable(data);
      });
    }
  }

  /**
   * @ngdoc object
   * @name relatorios.bdm.controller:BdmCtrl
   *
   * @description
   *
   */
  angular
    .module('relatorios.bdm')
    .controller('BdmCtrl', BdmCtrl);
}());
